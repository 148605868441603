;(function (u, n, i, v, e, r, s, a, l) {
  u[r] = {}
  u[r].uid = 'b105e42b-1f9a-4f9d-8b54-602a59d8f01c'
  u[r].m = ['getReferrerInfo', 'identify', 'integrationsReady', 'on', 'ready', 'track']
  u[r].queue = []
  u[r].f = function (t) {
    return function () {
      var l = Array.prototype.slice.call(arguments)
      l.unshift(t)
      u[r].queue.push(l)
      return u[r].queue
    }
  }
  for (var t = 0; t < u[r].m.length; t++) {
    l = u[r].m[t]
    u[r][l] = u[r].f(l)
  }
  a = n.createElement(v)
  a.src = e + '/us-' + u[r].uid + '.js'
  a.async = s
  n.getElementsByTagName(i)[0].appendChild(a)
})(window, document, 'head', 'script', 'https://cdn.getambassador.com', 'mbsy', true)
